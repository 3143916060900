export const TRANSLATIONS = {
    en_US: {
      "Show_latest": 'Show latest',
      "Show_last_6_months": 'Show last 6 months',
      "Copy_order": 'Copy order',
      "Username": 'Username',
      "Password": 'Password',
      "Forgot_password": 'Forgot password?',
      "Sign_in": 'Sign in',
      "Search":'Search',
      "Products":'Products',
      "Add_Product":'Add Product',
      "Dashboard":'Dashboard',
      "Sales_Order":'Order Registration',
      "Purchase_Order":'Purchase Order',
      "Supplier":'Supplier',
      "Customer":'Customer',
      "Product_Group":'Product Group',
      "Sales_Unit":'Sales Unit',
      "Kilogram":'Kilogram',
      "Product":'Product',
      "Productcode":'Productcode',
      "Previous":'Previous',
      "Next":'Next',
      "Show_entries":'Show entries',
      "Show":'Show',
      "Entries":'entries',
      "Show_page":'Showing',
      "Producttype": 'Producttype',
      "Concentration": 'Concentration',
      "Addition": 'Addition',
      "Description": 'Description',
      "Salesunit": 'Salesunit',
      "Partial": 'Partial',
      "Intrastat": 'Intrastat',
      "Save": 'Save',
      "Saved": 'Saved',
      "User": 'User',
      "Add User": 'Add User',
      "FirstName": 'FirstName',
      "LastName": 'LastName',
      "Email": 'Email',
      "Active": 'Active',
      "State": 'State',
      "View_User": 'View User',
      "Edit User": 'Edit User',
      "UserName": 'UserName',
      "Roles": 'Roles',
      "ConfirmPassword": 'ConfirmPassword',
      "PhoneNumber": 'PhoneNumber',
      "IsActive": 'IsActive',
      "Product_Details": 'Product Details',
      "Productgroup": 'Productgroup',
      "Purchase_Price": 'Purchase Price',
      "Price": 'Price',
      "Start_date": 'Start date',
      "End_date": 'End date',
      "Add_Purchase_Price": 'Add Purchase Price',
      "Sales_Price": 'Sales Price',
      "Add_Salese_Price": 'Add Salese Price',
      "Transport_Price": 'Transport Price',
      "Id": 'Id',
      "Transporter": 'Transporter',
      "Pricingtype": 'Pricingtype',
      "Add_Transport_Price": 'Add Transport Price',
      "Add_Sales_Order": 'Add Sales Order',
      "Reference": 'Reference',
      "Order_Date": 'Order Date',
      "Total_amount": 'Total amount',
      "Sales_Order_Details": 'Order registration details',
      "Loading_Date": 'Loading Date',
      "Quantity": 'Quantity',
      "Amount": 'Amount',
      "Add_Pursase_Order": 'Add Pursase Order',
      "Invoice": 'Invoice',
      "Invoice_date": 'Invoice date',
      "Journal": 'Journal',
      "Expiration_Date": 'Expiration Date',
      "Purchase_Order_Details": 'Purchase Order Details',
      "Add_row": 'Add row',
      "GlCode": 'GlCode',
      "VATCode": 'VATCode',
      "VATAmount": 'VATAmount',
      "Reporting_Date": 'Reporting Date',
      "Purchase_Unit": 'Purchase Unit',
      "Approver": 'Approver',
      "Add_Price": 'Add Price',
      "Copy_Product": 'Copy Product',
      "Select": 'Select...',
      "Task": 'Task',
      "Task_Overview": 'Task Overview',
      "Tasktype": 'Tasktype',
      "Subject": 'Subject',
      "TaskStatus": 'TaskStatus',
      "Action": 'Action',
      "Edit_Task": 'Edit Task',
      "Add_Task": 'Add Task',
      "Approve": 'Approve',
      "Customer_Note": 'Customer Note',
      "File": 'File',
      "Choose_File": 'Choose File',
      "Purchase_order": 'PurchaseOrder',
      "Please_set_pricetype": 'Please set pricetype',
      "Submit": 'Submit',
      "Edit": 'Edit',
      "Click_or_Drop": 'Click or Drop some files here!',
      "Purchase_Order_Edit": 'Purchase Order Edit',
      "Sales_Order_Edit": 'Sales Order Edit',
      "Send_to_Exact": 'Send to Exact',
      "Sending": 'Sending',
      "Loading_date": 'Loading Date',
      "Attachments": 'Attachments',
      "Reference_customer": 'Reference Customer',
      "Total_Purchase": 'Total Purchase',
      "Total_Sales": 'Total Sales',
      "DocumentType": 'DocumentType',
      "IsTransport": 'IsTransport',
      "PackingSlip": '(ICA) Packing slip number/Ship name',
      "Container": 'Container',
      "Shipping": 'Shipping',
      "Purchase_Amount": 'Purchase Amount',
      "Sales_Amount": 'Sales Amount',
      "Packing_slip_number": '(ICA) Packing slip number/Ship name',
      "Container_number": 'Container Number',
      "ShippingDocumentnumber": 'Shipping Number',
      "Add": 'Add',
      "ReportingDate": 'Report Date',
      "Number": 'Number',
      "Quality": 'Quality',
      "Complete": 'Complete',
      "No_Result": 'No Result',
      "Total": 'Total',
      "Purchase": 'Purchase',
      "Transport": 'Transport',
      "Where_do_filter": 'Where do you want to filter on',
      "TransporterCode": 'TransporterCode',
      "TransporterName": 'TransporterName',
      "Arrival_date": 'ArrivalDate',
      "Manual": 'Add purchase invoice',
      "Vat": 'Vat',
      "Manual_Edit": 'Edit purchase invoice',
      "Sales_Quantity": 'Sales Quantity',
      "Purchase_Quantity": 'Purchase Quantity',
      "Show_all": 'Show all',
      "Show_just_not_completed": 'Show just not completed',
      "Show_just_completed": 'Show just completed',
      "Invoicing": 'Invoicing',
      "Send_salesinvoice": 'Send salesinvoice',
      "Loading_week": 'Loading week',
      "Date": 'Date',
      "Week": 'Week',
      "Sales": 'Sales',
      "Demurrage": 'Demurrage',
      "Product_Name": 'Product Name',
      "Packing_date": 'Packing Date',
      "SalesOrderExactSend": 'Sales Order Exact Send',
      "PurchaseOrderExactSend": 'Purchase Order Exact Send',
      "already_purchaseinvoice": 'This supplier already has a purchaseinvoice on this order registration!',
      "Results_of": 'results of',
      "Result_on_page": 'result on page',
      "Quick_search": 'Quick search',
      "Copy": 'Copy',
      "Users": 'Users',
      "Delete": 'Delete',
      "Edit_project_detail": 'Edit project detail',
      "Add_product": 'Add product',
      "Order": 'Order',
      "Add_purchase": 'Add purchase',
      "Custom_products": 'Custom Products?',
      "Filter": 'Filter',
      "Add_filter": 'Add filter',
      "Filter_by_this_field": 'Filter by this field',
      "FilterDate": 'Filter date',
      "Hide_column": 'Hide column',
      "already_invoice": 'This invoice number has previously been used for this supplier',
      "ExactBooking": 'Exact Booking',
      "ExactStatus": "Exact status",
      "ExactError": "Exact error",
      "Comments": 'Comments',
      "NewOrder": 'NewOrder',
      "BookingNumber": 'BookingNumber',
      "Edit_Purchase_detail": 'Edit Purchase detail',
      "Edit_Order_detail": 'Edit Order detail',
      "Shipping_company": 'Shipping company',
      "Picking_reference": 'Picking reference',
      "PickingDate": 'PickingDate',
      "RemoveReference": 'RemoveReference',
      "CreateProFormaInvoice": 'Create Pro Forma invoice',
      "SalesInvoice": 'SalesInvoice',
      "ProFormaInvoice": 'Pro Forma Invoice',
      "DocDelete": 'DocDelete',
      "Success": 'Success',
      "DelDate": 'Del. Date',
      "NumberUnit": 'Number unit',
      "PaymentCondition": 'Payment condition',
      "ExpireDate": 'ExpireDate',
      "TotalExcl": 'Total excl.',
      "TotalIncl": 'Total incl.',
      "TotalVat": 'Total Vat',
      "InvoiceDate": 'Invoice date',
      "PaymentReference": 'Payment reference',
      "InvoiceNumber": 'Invoice number',
      "CustomerNumber": 'CustomerNumber',
      "InvoiceAmount": 'Invoice acmount',
      "InvoiceNevemaPdf": 'The supplied goods remain property of Nevema untill they have been fully paid. After the expire date of this invoice the legal interest of 2% and all legal costs are on the account of the customer. For all transactions (unless agreed otherwise) our General Conditions are valid, they have been deposited at the Chamber of Commerce in Zwolle. If required we can send you a copy for free. The judge of our place of business (file number 05023998) is the competent judge.',
      "Pakbon": 'Pakbon',
      "PackingFilter": 'Pakbon',
      "Language": 'Language',
      "Text lines": 'Text lines',
      "Add Text": 'Add Text',
      "Reports": 'Reports',
      "Month End": 'Month End',
      "Transport Amount": 'Transport Amount',
      "Transport Quantity": 'Transport Quantity',
      "Purchase ID": 'Purchase ID',
      "Sales ID": 'Sales ID',
      "Transport ID": 'Transport ID',
      "ItemCode": 'ItemCode',
      "Sales number": 'Sales number',
      "Purchase number": 'Purchase number',
      "Transport number": 'Transport number',
      "Purchase Order": 'Purchase Order',
      "Transport Order": 'Transport Order',
      "SalesExactBooking": 'SalesExact Booking',
      "PurchseExactBooking": 'PurchseExact Booking',
      "TransportExactBooking": 'TransportExact Booking',
      "Excel export": 'Excel export',
      "Sales Amount": 'Sales Amount',
      "Purchase Amount": 'Purchase Amount',
      "Change Price": 'Change Price',
      "Article seg 1": 'Article seg 1',
      "Article seg 2": 'Article seg 2',
      "Article seg 3": 'Article seg 3',
      "Start date": 'Start Date',
      "Unit": 'Unit',
      "End Date": 'End Date',
      "ProductCode": 'ProductCode',
      "Relationship Code": 'Relationship Code',
      "Relationship Name": 'Relationship Name',
      "Price type": 'Price Type',
      "Period Date": 'Period Date',
      "Add Period Date": 'Add Period Date',
      "Year": 'Year',
      "Month": 'Month',
      "NewDate": 'NewDate',
      "Edit Period Date": 'Edit Period Date',
      "already_product": 'Duplicated Product!',
      "A price is already available for this start date": 'A price is already available for this start date',
      "OrderId": 'OrderId',
      "The New password must be at least 6 characters long.": "The New password must be at least 6 characters long.",
      "The new password and confirmation password do not match.": "The new password and confirmation password do not match.",
      "The user name or password is incorrect.": "The user name or password is incorrect.",
      "Incorrect password.": "Incorrect password.",
      "IP address not recognized.": "IP address not recognized",
      "Username and password combination not found.": "Username and password combination not found.",
      "Password policy expired. Please reset your password to login.": "Password policy expired. Please reset your password to login. You will be redirected...",
      "Current password was incorrect.": "Current password was incorrect.",
      "The New password must contain a symbol, uppercase letter, lowercase letter and a number.": "The new password must contain a symbol, uppercase letter, lowercase letter and a number."
    },

    nl_BE: {
      "Current password was incorrect.": "Het huidige wachtwoord is onjuist.",
      "The New password must contain a symbol, uppercase letter, lowercase letter and a number.": "Het nieuwe wachtwoord moet een symbool, hoofdletter, kleine letter en een nummer bevatten.",
      "Username and password combination not found.": "Gebruikersnaam en wachtwoord combinatie niet gevonden.",
      "The New password must be at least 6 characters long.": "Het nieuwe wachtwoord moet minimaal 6 karakters lang zijn.",
      "The new password and confirmation password do not match.": "Het nieuwe wachtwoord en bevestiging hiervan zijn niet gelijk",
      "The user name or password is incorrect.": "Gebruikersnaam of wachtwoord is incorrect.",
      "Password policy expired. Please reset your password to login.": "Uw wachtwoord is verlopen. U wordt omgeleid...",
      "IP address not recognized.": "IP adres is niet bekend",
      "Incorrect password.": "Incorrect wachtwoord.",
      "Show_latest": 'Toon de nieuwste',
      "Show_last_6_months": 'Toon laatste 3 maanden',
      "Copy_order": 'Kopieer bestelling',
      "Username": 'Gebruikersnaam',
      "Password": 'Wachtwoord',
      "Forgot_password": 'Wachtwoord vergeten?',
      "Sign_in": 'Aanmelden',
      "Search":'Zoeken',
      "Products":'Artikelen',
      "Add_Product":'Toevoegen product',
      "Dashboard":'Dashboard',
      "Sales_Order":'Orderregistratie',
      "Purchase_Order":'Inkoopfactuur',
      "Purchase_Order_Edit":'Bewerk Inkoopfactuur',
      "Supplier":'Leverancier',
      "Customer":'Klant',
      "Product_Group":'Artikelgroep',
      "Sales_Unit":'Verkoop eenheid',
      "Kilogram":'Kilogram',
      "Product":'Artikel',
      "Productcode":'Artikelcode',
      "Previous":'Vorige',
      "Next":'Volgende',
      "Show_entries":'Toon records',
      "Show":'Toon',
      "Entries":'Records',
      "Show_page":'Tonen',
      "Producttype": 'Artikelsoort',
      "Concentration": 'Concentratie',
      "Addition": 'Toevoeging',
      "Description": 'Omschrijving',
      "Salesunit": 'Verkoopeen heid',
      "Partial": 'Delen',
      "Intrastat": 'Intrastat',
      "Save": 'Opslaan',
      "Saved": 'Opgeslagen',
      "User": 'Gebruiker',
      "Add User": 'Voeg gebruiker toe',
      "FirstName": 'Voornaam',
      "LastName": 'Achternaam',
      "Email": 'E-mail',
      "Active": 'Actief',
      "State": 'Status',
      "View_User": 'Bekijk gebruiker',
      "Edit User": 'Bewerk gebruiker',
      "UserName": 'Gebruikersnaam',
      "Roles": 'Rollen',
      "ConfirmPassword": 'Bevestig wachtwoord',
      "PhoneNumber": 'Telefoonnummer',
      "IsActive": 'Is actief',
      "Product_Details": 'Product details',
      "Productgroup": 'Productgroep',
      "Purchase_Price": 'Inkoop prijs',
      "Price": 'Prijs',
      "Start_date": 'Begindatum',
      "End_date": 'Einddatum',
      "Add_Purchase_Price": 'Inkoopprijs toevoegen',
      "Sales_Price": 'Verkoop prijs',
      "Add_Salese_Price": 'Verkoopprijs toevoegen',
      "Transport_Price": 'Transportprijs',
      "Id": 'Id',
      "Transporter": 'Vervoerder',
      "Pricingtype": 'Prijstype',
      "Add_Transport_Price": 'Voeg transportprijs toe',
      "Add_Sales_Order": 'Verkooporder toevoegen',
      "Reference": 'Referentie',
      "Order_Date": 'Besteldatum',
      "Total_amount": 'Totaalbedrag',
      "Sales_Order_Details": 'Orderregistratie details',
      "Loading_Date": 'Laad datum',
      "Quantity": 'Aantal',
      "Amount": 'Bedrag',
      "Add_Pursase_Order": 'Toevoegen Inkoopfactuur',
      "Invoice": 'Factuur',
      "Invoice_date": 'Factuurdatum',
      "Journal": 'Dagboek',
      "Expiration_Date": 'Vervaldatum',
      "Purchase_Order_Details": 'Inkoopfactuur details',
      "Add_row": 'Rij toevoegen',
      "GlCode": 'Grootboekrekening',
      "VATCode": 'BTW code',
      "VATAmount": 'BTW bedrag',
      "Reporting_Date": 'Rapportage datum',
      "Purchase_Unit": 'Inkoop eenheid',
      "Approver": 'Goedkeurder',
      "Add_Price": 'Prijs toevoegen',
      "Copy_Product": 'Artikel kopiëren',
      "Select": 'Selecteer...',
      "Task": 'Taak',
      "Task_Overview": 'Taak overzicht',
      "Tasktype": 'Taaktype',
      "Subject": 'Onderwerp',
      "TaskStatus": 'Taak status',
      "Action": 'Actie',
      "Edit_Task": 'Bewerk taak',
      "Add_Task": 'Toevoegen taak',
      "Approve": 'Goedkeuren',
      "Customer_Note": 'Klant notitie',
      "File": 'Bestand',
      "Choose_File": 'Kiezen bestand',
      "Purchase_order": 'Inkoopfactuur',
      "Please_set_pricetype": 'Instellen prijstype',
      "Submit": 'Indienen',
      "Edit": 'Bewerk',
      "Click_or_Drop": 'Klik of sleep uw bestanden!',
      "Sales_Order_Edit": 'Verkooporder bewerken',
      "Send_to_Exact": 'Verstuur naar Exact',
      "Sending": 'Verzending',
      "Loading_date": 'Laad datum',
      "Attachments": 'Bijlagen',
      "Reference_customer": 'Referentie klant',
      "Total_Purchase": 'Totaal inkoop',
      "Total_Sales": 'Totaal verkoop',
      "DocumentType": 'DocumentType',
      "IsTransport": 'Is transport',
      "PackingSlip": '(ICA) Pakbon nummer/Scheepsnaam',
      "Container": 'Container nummer',
      "Shipping": 'Vrachtbrief nummer',
      "Purchase_Amount": 'Inkoop bedrag',
      "Sales_Amount": 'Verkoop bedrag',
      "Packing_slip_number": '(ICA) Pakbon nummer/Scheepsnaam',
      "Container_number": 'Container nummer',
      "ShippingDocumentnumber": 'Vrachtbrief nummer',
      "Add": 'Toevoegen',
      "ReportingDate": 'Laaddatum',
      "Number": 'Nummer',
      "Quality": 'Controle',
      "Complete": 'Compleet',
      "No_Result": 'Geen resultaat',
      "Total": 'Totaal',
      "Purchase": 'Inkoop',
      "Transport": 'Vervoer',
      "Where_do_filter": 'Waar wilt u op filteren?',
      "TransporterCode": 'Vervoerder code',
      "TransporterName": 'Vervoerder naam',
      "Arrival_date": 'Datum binnenkomst',
      "Manual": 'Handmatige regel toevoegen',
      "Vat": 'Vat',
      "Manual_Edit": 'Handmatige regel bewerken',
      "Sales_Quantity": 'Verkoop Aantal',
      "Purchase_Quantity": 'Inkoop Aantal',
      "Show_all": 'Toon alles',
      "Show_just_not_completed": 'Toon niet voltooid',
      "Show_just_completed": 'Toon voltooid',
      "Invoicing": 'Facturatie',
      "Send_salesinvoice": 'Aanmaken verkoopfactuur',
      "Loading_week": 'Laad week',
      "Date": 'Datum',
      "Week": 'Week',
      "Sales": 'Verkoop',
      "Demurrage": 'Demurrage',
      "Product_Name": 'Product Naam',
      "Packing_date": 'Afhaal datum',
      "SalesOrderExactSend": 'Verkooporder naar Exact',
      "PurchaseOrderExactSend": 'Inkooporder naar Exact',
      "already_purchaseinvoice": 'Deze leverancier heeft al een inkoopfactuur bij deze orderregistratie!',
      "Results_of": 'Resultaten van',
      "Result_on_page": 'Resultaten op pagina',
      "Quick_search": 'Snel zoeken',
      "Copy": 'Kopiëren',
      "Users": 'Gebruikers',
      "Delete": 'Verwijderen',
      "Edit_project_detail": 'Productdetails bewerken',
      "Add_product": 'Toevoegen Product',
      "Order": 'Order',
      "Add_purchase": 'Toevoegen inkoop',
      "Custom_products": 'Handmatige regel',
      "Filter": 'Filter',
      "Add_filter": 'Toevoegen filter',
      "FilterDate": 'Filter datum',
      "Filter_by_this_field": 'Filteren op dit veld',
      "Hide_column": 'Kolom verbergen',
      "already_invoice": 'Dit factuurnummer is al eerder gebruikt voor deze leverancier',
      "ExactBooking": 'Exact boeking',
      "ExactStatus": "Exact status",
      "ExactError": "Exact error",
      "Comments": 'Opmerkingen',
      "NewOrder": 'NieuweBestelling',
      "BookingNumber": 'BoekingNummer',
      "Edit_Purchase_detail": 'Aankoopdetails bewerken',
      "Edit_Order_detail": 'Order bewerken',
      "Shipping_company": 'Rederij',
      "Picking_reference": 'Uithaalreferentie',
      "PickingDate": 'Uithalen voor',
      "RemoveReference": 'UithaalReferentie',
      "CreateProFormaInvoice": 'Maak een Pro Forma-factuur',
      "SalesInvoice": 'Verkoopfactuur',
      "ProFormaInvoice": 'Pro Forma factuur',
      "DocDelete": 'DocVerwijderen',
      "Success": 'Succes',
      "DelDate": 'Afl. datum',
      "NumberUnit": 'Aantal eenheid',
      "PaymentCondition": 'Betalingsconditie',
      "ExpireDate": 'Vervaldatum',
      "TotalExcl": 'Totaal Excl.',
      "TotalIncl": 'Totaal Incl.',
      "TotalInclVat": 'Totaal Incl. BTW',
      "totalExclVat": 'Totaal Excl. BTW',
      "TotalVat": 'Totaal BTW',
      "InvoiceDate": 'Factuurdatum',
      "PaymentReference": 'Bij betaling vermelden',
      "InvoiceNumber": 'Factuurnummer',
      "CustomerNumber": 'clientnummer',
      "InvoiceAmount": 'Factuurbedrag',
      "InvoiceNevemaPdf": 'De geleverde goederen blijven eigendom van Nevema totdat deze volledig zijn betaald. Na het verstrijken van de betalingstermijn wordt over het verschuldigde bedragde wettelijke rente +2% berekend en zijn incassokosten verschuldigd. Voor alle transacties, met uitsluiting van andere voorwaarden, gelden onze bij de KvK te Zwollegedeponeerde Algemene Voorwaarden, waarvan op aanvraag kosteloos een exemplaar wordt verstrekt. De rechter van onze vestigingsplaats is de bevoegde rechter.(Zie ommezijde) Handelsregister onder dossiernummer 05023998.',
      "Pakbon": 'Pakbon',
      "PackingFilter": 'Pakbon',
      "Language": 'Taal',
      "Text lines": 'Tekstregels',
      "Add Text": 'Voeg tekst toe',
      "Reports": 'Rapporten',
      "Month End": 'Maandafsluiting',
      "Transport Amount": 'Transport Aantal',
      "Transport Quantity": 'Transporthoeveelheid',
      "Purchase ID": 'Inkoop ID',
      "Sales ID": 'Verkoop ID',
      "Transport ID": 'Transport ID',
      "ItemCode": 'Artikelcode',
      "Sales number": 'Verkoop Bedrag',
      "Purchase number": 'Inkoop Bedrag',
      "Transport number": 'Transport bedrag',
      "Purchase Order": 'Bestelling',
      "Transport Order": 'Transportopdracht',
      "SalesExactBooking": 'Verkoop Exactboeking',
      "PurchseExactBooking": 'Inkoop Exactboeking',
      "TransportExactBooking": 'Transport Exactboeking',
      "Excel export": 'Excel export',
      "Sales Amount": 'Verkoop Aantal',
      "Purchase Amount": 'Inkoop Aantal',
      "Change Price": 'Prijzen wijzigen',
      "Article seg 1": 'Artikel seg 1',
      "Article seg 2": 'Artikel seg 2',
      "Article seg 3": 'Artikel seg 3',
      "Start date": 'BeginDatum',
      "Unit": 'Eenheid',
      "End Date": 'EindDatum',
      "ProductCode": 'Product Code',
      "Relationship Code": 'Relatie Code',
      "Relationship Name": 'Relatienaam Name',
      "Price type": 'Soort Prijs',
      "Period Date": 'Periode Datum',
      "Add Period Date": 'Voeg Periode Datum',
      "Year": 'Jaar',
      "Month": 'Maand',
      "NewDate": 'NieuweDatum',
      "Edit Period Date": 'Bewerken Periode Datum',
      "already_product": 'Gedupliceerd product!',
      "A price is already available for this start date": 'Voor deze startdatum is al een prijs aanwezig',
      "OrderId": 'OrderId',
    }
  };