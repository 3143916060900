import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import SessionManager from '../../components/session_manage';
import API from '../../components/api'
import Axios from 'axios';
import { trls } from '../../components/translate';
import "react-datepicker/dist/react-datepicker.css";
import * as Common from '../../components/common';
import DatePicker from "react-datepicker";

import DraggableModalDialog from '../../components/draggablemodal';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class UpdateChangePrice extends Component {
    _isMounted = false;
    constructor(props) {
        super();
        const { updatePriceData } = props;
        this.state = {  
            startDate: updatePriceData['BeginDatum'],
            endDate: updatePriceData['EindDatum'],
            startDateFlag: false,
            endDateFlag: false,
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (event) => {
        const { updatePriceData } = this.props
        event.preventDefault();
        const clientFormData = new FormData(event.target);
        const data = {};
        let params = [];
        for (let key of clientFormData.keys()) {
            data[key] = clientFormData.get(key);
        }
        var headers = SessionManager.shared().getAuthorizationHeader();
        params = {
            Pricingtype: updatePriceData['Soort prijs'],
            PricingId : updatePriceData['PriceId'],
            price  : data['price'],
            startdate   : Common.formatDateSecond(data['startDate']),
            endDate   : Common.formatDateSecond(data['endDate']),
        }
        Axios.post(API.UpdatePricesTable, params, headers)
        .then(result => {
            this.onHide();
        });
    }

    onHide = () => {
        this.props.onHide();
        this.props.onGetPriceData();
        this.setState({
            startdate:'',
            enddate:''
        })
    }

    onChangeDate = (date, type) => {
        if (type === 'start') {
            this.setState({startDate: date, startDateFlag: true})
        } else {
            this.setState({endDate: date, endDateFlag: true})
        }
    }

    dateValueKeyDown = (event, mode) => {
        const charCode = event.which || event.keyCode;

        if (charCode === 9 ) {

            const newDate = new Date();
            newDate.setDate(parseInt(event.target.value));

            this.onChangeDate(newDate, mode);
        }
    }

    render(){
        const { updatePriceData } = this.props
        const { startDate, endDate } = this.state
        return (
            <Modal
                show={this.props.show}
                dialogAs={DraggableModalDialog}
                onHide={()=>this.onHide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Edit')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="container product-form" onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text"> 
                            <Form.Control type="text" name="price" required defaultValue={updatePriceData['Prijs']} placeholder={trls("Purchase_Price")} />
                            <label className="placeholder-label">{trls('Price')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <DatePicker name="startDate" className="myDatePicker" dateFormat={['dd-MM-yyyy', 'd-M-yyyy']} onKeyDown={(e) => this.dateValueKeyDown(e, 'start')} selected={new Date(startDate)} onChange = {(value)=>this.onChangeDate(value, 'start')}/>
                            <label className="placeholder-label">{trls('Start date')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <DatePicker name="endDate" className="myDatePicker" dateFormat={['dd-MM-yyyy', 'd-M-yyyy']} onKeyDown={(e) => this.dateValueKeyDown(e, 'end')} selected={new Date(endDate)} onChange = {(value)=>this.onChangeDate(value, 'end')}/>
                            <label className="placeholder-label">{trls('End Date')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="submit" style={{width:"100px"}}>{trls('Save')}</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateChangePrice);