import React, {Component} from 'react'
import { trls } from '../../components/translate';
import { Button, Spinner } from 'react-bootstrap';
import { Form, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { connect } from 'react-redux';
import SessionManager from '../../components/session_manage';
import API from '../../components/api'
import Axios from 'axios';
import Priceform from './product_priceform'
import Updateproductform from './update_product'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as Common from '../../components/common'
import FlashMassage from 'react-flash-message'
import Pricelinechangeform from './pricelinechange_form';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({

});

class Productdtail extends Component {
    constructor(props) {
        super(props);
        this.state ={
            purpriceDatalist: [],
            salespriceDatalist: [],
            transportpriceDatalist: [],
            productDetail: [],
            modalShow: false,
            price_flag:"",
            exactFlag: false,
            sendingFlag: false,
            editPriceFlag: false,
            editPriceData: [],
            priceLineData: [],
            newPrice: '',
            productDocList: [],
            hasToggledLatestPrices: false,
            option1: "latest",
            option2: "latest",
            option3: "latest",
        }
      }
    componentWillUnmount() {
        this._isMounted = false
    }
    componentDidMount() {
        this._isMounted = true;
        this.getProductDetails(); //And limit results to 1
        this.getPurchasePriceData();
        this.getSalespriceData();
        this.getTransportPriceData();
        this.getDocumentType();
    }

    getDocumentType () {
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetDocumenttypesDropdown, headers)
        .then(result => {
            this.setState({typeData: result.data.Items});
        });
    }
    
    getProductDetails() {
        let params = {
            id: this.props.productid
        }
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetProduct, params, headers)
        .then(result => {
            if(this._isMounted){
                params = {
                    productId: this.props.productid
                }
                Axios.post(API.GetProductDocuments, params, headers)
                .then(result => {
                    if(result.data.Items){
                        this.setState({productDocList: result.data.Items})
                    }

                    const query = document.querySelector("#priceTbody");
                    if(this.state.purpriceDatalist.length > 0 && !this.state.hasToggledLatestPrices && query) {

                        const setting1 = window.localStorage.getItem('nevema_productButtonSetting1');
                        const setting2 = window.localStorage.getItem('nevema_productButtonSetting2');
                        const setting3 = window.localStorage.getItem('nevema_productButtonSetting3');

                        if(this.state.option1) {
                            this.setState({option1: setting1});

                            if(this.state.option1 === "all") {
                                this.filterPricesAll(null, "priceTbody", 1);
                            } else {
                                this.filterPricesLatest(null, "priceTbody", 1);
                            }
                        }

                        if(this.state.option2) {
                            this.setState({option2: setting2});

                            if(this.state.option2 === "all") {
                                this.filterPricesAll(null, "salesTbody", 1);
                            } else {
                                this.filterPricesLatest(null, "salesTbody", 1);
                            }
                        }

                        if(this.state.option3) {
                            this.setState({option3: setting3});

                            if(this.state.option3 === "all") {
                                this.filterPricesAll(null, "transportTbody", 3);
                            } else {
                                this.filterPricesLatest(null, "transportTbody", 3);
                            }
                        }

                        this.setState({hasToggledLatestPrices: true});
                    }
                })
                this.setState({productDetail: result.data.Items})
            }
        });
    }

    getPurchasePriceData = () => {
        let params = {
            productid: this.props.productid
        }
        this.setState({
            editPriceData: [],
            editPriceFlag: false
        })
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetPurchasePrices, params, headers)
        .then(result => {
            if(this._isMounted){    
                this.setState({purpriceDatalist: result.data.Items})
            }
        });
    }

    getSalespriceData = () => {
        let params = {
            productid: this.props.productid
        }
        this.setState({
            editPriceData: [],
            editPriceFlag: false
        })
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetSalesPrices, params, headers)
        .then(result => {
            if(this._isMounted){    
                this.setState({salespriceDatalist: result.data.Items})
            }
        });
    }

    getTransportPriceData = () =>{
        let params = {
            productid: this.props.productid
        }
        this.setState({
            editPriceData: [],
            editPriceFlag: false
        })
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetTransportPrices, params, headers)
        .then(result => {
            if(this._isMounted){    
                this.setState({transportpriceDatalist: result.data.Items})
            }
        });
    }

    formatDate = (startdate) =>{
        var dd = new Date(startdate).getDate();
        var mm = new Date(startdate).getMonth()+1; 
        var yyyy = new Date(startdate).getFullYear();
        var formatDate = '';
        if(dd<10) 
        {
            dd='0'+dd;
        } 

        if(mm<10) 
        {
            mm='0'+mm;
        } 
        formatDate = dd+'-'+mm+'-'+yyyy;
        return formatDate;
    }
    formatNumber = (num) => {
        return  "€" + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    priceApproveConfirm = (event) => {
        let priceid = event.currentTarget.id;
        let priceType = event.currentTarget.name
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to approve this price?',
            buttons: [
              {
                label: 'OK',
                onClick: () => {
                   this.priceApprove(priceid, priceType)
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });
    }

    priceApprove = (priceid, priceType) => {
        this._isMounted=true;
        let url = '';
        let params = {
            priceid:priceid
        }
        if(priceType==="purchase"){
            url = API.ApprovePurchasePrice;
        }else if(priceType==="sales"){
            url = API.ApproveSalesPrice;
        }else{
            url = API.ApproveTransportPrice;
        }
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(url, params, headers)
        .then(result => {
            if(this._isMounted){    
                this.getPurchasePriceData();
                this.getSalespriceData();
                this.getTransportPriceData();
            }
        });
    }

    generateProductXml = () => {
        this.setState({sendingFlag: true})
        let headers = SessionManager.shared().getAuthorizationHeader();
        var params = {
            productid: this.props.productid
        }
        Axios.post(API.PostProductsExact, params, headers)
        .then(result => {
            this.setState({exactFlag: true, sendingFlag: false})
        });
    }

    viewPurchaseLine = (startDate, endDate, newPrice, price_flag, transportCode) => {
        let headers = SessionManager.shared().getAuthorizationHeader();
        let params = {};
        let URL = "";
        if(price_flag === 1 || price_flag === 2){
            params = {
                productid: this.props.productid,
                startdate: Common.formatDateSecond(startDate),
                enddate: Common.formatDateSecond(endDate),
            }
            if(price_flag === 1){
                URL = API.GetPurchaseLinesToChange;
            }else{
                URL = API.GetSalesLinesToChange;
            }
        }else{
            URL = API.GetTransportLinesToChange;
            params = {
                transportercode: transportCode,
                startdate: Common.formatDateSecond(startDate),
                enddate: Common.formatDateSecond(endDate),
            }
        }
        Axios.post(URL, params, headers)
        .then(result => {
            if(result.data.Success){
                this.setState({priceLineShowModal: true, newPrice: newPrice, priceLineData: result.data.Items})
            }
        })
    }

    changePurchasePrice = (id) => {
        let purpriceDatalist = this.state.purpriceDatalist;
        purpriceDatalist.map((data, index)=>{
            if(data.Id===id){
                if(data.checked){
                    data.checked = false
                }else{
                    data.checked = true
                }
            }
            return data;
        });
        this.setState({purpriceDatalist: purpriceDatalist});
    }

    deletePrice = (id, mode) => {
        let headers = SessionManager.shared().getAuthorizationHeader();
        let URL = '';
        if(mode===1){
            URL = API.DeletePurchasePrice;
        }else{
            URL = API.DeleteSalesPrice;
        }
        let params = {
            id: id
        }
        Axios.post(URL, params, headers)
        .then(result => {
            if(result.data.Success){
                if(mode===1){
                    this.getPurchasePriceData();
                }else{
                    this.getSalespriceData();
                }
            }
        })
    }

    removeState = () => {
        this.setState({
            modalShow: false,
            price_flag:"",
            exactFlag: false,
            sendingFlag: false,
            editPriceFlag: false,
            editPriceData: [],
            priceLineData: [],
            newPrice: ''
        })
    }

    onHide = () => {
        this.props.onHide();
        Common.hideSlideForm();
    }

    downLoadFile = (fileId) => {
        window.open(API.GetDownloadFile+fileId);
    }

    filterPricesLatest = (event, className, dateTableIndex) => {
        const tableBody = document.querySelectorAll("#" + className + " tr");
        const startDates = [];

        if(this.state.hasToggledLatestPrices === false) {
            this.setState({hasToggledLatestPrices: true});
        }

        for (const key in tableBody) {
            if (Object.hasOwnProperty.call(tableBody, key)) {
                const startDate = tableBody[key].querySelectorAll("td")[dateTableIndex].innerText;
                const dateParts = startDate.split("-");
                const newDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]

                startDates.push(new Date(newDate));
            }
        }

        if(dateTableIndex === 1) {
            this.filterPricesLatestPurchaseSale(tableBody, startDates)
        } else if(dateTableIndex === 3) {
            this.filterPricesLatestTransport(tableBody, startDates)
        }
    }

    filterPricesLatestTransport = (tableBody, tableRows) => {
        const records = [];
        const showRecords = [];

        //Setup transport records
        for (let index = 0; index < tableBody.length; index++) {
            const rowData = tableBody[index].querySelectorAll("td");
            const startDate = rowData[3].innerText;
            const dateParts = startDate.split("-");
            const newDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
            const record = {transporter: rowData[0].innerText, pricetype: rowData[1].innerText, date: new Date(newDate), index: index, hide: false};
            records.push(record);
        }

        records.map(a=>a.hide=true);

        //Setup showRecords records
        for (let index = 0; index < records.length; index++) {
            const record = records[index];

            if (showRecords.filter(e => e.transporter === record.transporter && e.pricetype === record.pricetype).length > 0) {
                //Check if current date is higher then the once in array
                const recordInArray = showRecords.find(x => x.transporter === record.transporter && x.pricetype === record.pricetype);
                
                if(new Date(record.date) > new Date(recordInArray.date)) {
                    const index = showRecords.indexOf(recordInArray);
                    showRecords[index] = record;
                }
            } else {
                showRecords.push(record);
            }
        }

        //Hide rows where hide=True
        for (let index = 0; index < records.length; index++) {
            const show = showRecords.find(x => x.index === records[index].index);
            if(!show) {
                tableBody[index].classList.add("d-none");
            }
        }

    }

    filterPricesLatestPurchaseSale = (tableBody, startDates) => {
        const latestDate = new Date(Math.max.apply(null,startDates));
        const dateIndex = startDates.map(Number).indexOf(+latestDate);

        for (let index = 0; index < startDates.length; index++) {
            if(index !== dateIndex) {
                tableBody[index].classList.add("d-none");
            }
        }
    }

    filterPricesAll = (event, className) => {
        const tableBody = document.querySelectorAll("#" + className + " tr");
        
        if(this.state.hasToggledLatestPrices === false) {
            this.setState({hasToggledLatestPrices: true});
        }

        for (const key in tableBody) {
            if (Object.hasOwnProperty.call(tableBody, key)) {
                tableBody[key].classList.remove("d-none");
            }
        }
    }

    filterPriceToggle = (event, className, dateTableIndex) => {
        if(event.target.value === "all") {
            this.filterPricesAll(event, className, dateTableIndex);
        } else if(event.target.value === "latest") {
            this.filterPricesLatest(event, className, dateTableIndex);
        }

        if(className === "priceTbody") {
            this.setState({option1: event.target.value === "all" ? "all" : "latest"});
            window.localStorage.setItem('nevema_productButtonSetting1', event.target.value === "all" ? "all" : "latest");
        }
        
        if(className === "salesTbody") {
            this.setState({option2: event.target.value === "all" ? "all" : "latest"});
            window.localStorage.setItem('nevema_productButtonSetting2', event.target.value === "all" ? "all" : "latest");
        } 
        
        if(className === "transportTbody") {
            this.setState({option3: event.target.value === "all" ? "all" : "latest"});
            window.localStorage.setItem('nevema_productButtonSetting3', event.target.value === "all" ? "all" : "latest");
        }
    }

    render () {
        let detailData = [];
        let purpriceData = [];
        let salespriceData = [];
        let transportData = [];
        if(this.state.productDetail[0]){
            detailData = this.state.productDetail[0];
        }
        if(this.state.purpriceDatalist){
            purpriceData = this.state.purpriceDatalist
        }
        if(this.state.salespriceDatalist){
            salespriceData = this.state.salespriceDatalist;
        }
        if(this.state.transportpriceDatalist){
            transportData=this.state.transportpriceDatalist;
        }
        
        const pricingtypelist = {'1' :'Blokvracht','2' :'Eenheidsprijs'}
        const { productDocList } = this.state;
        return (
            <div className = "slide-form__controls open slide-product__detail">
                <div style={{marginBottom:30, padding:"0 20px"}}>
                    <i className="fas fa-times slide-close" style={{ fontSize: 20, cursor: 'pointer'}} onClick={()=>this.onHide()}></i>
                </div>
                <div className="content__header content__header--with-line product-detail__data--detail">
                    <h2 className="title">{trls("Product_Details")}</h2>
                </div>
                <div className="place-and-orders product-detail_main">
                    {this.state.exactFlag&&(
                        <div>
                            <FlashMassage duration={2000}>
                                <div className="alert alert-success" style={{marginTop:10}}>
                                    <strong><i className="fas fa-check-circle"></i> Success!</strong>
                                </div>
                            </FlashMassage>
                        </div>
                    )
                    }
                    {this.state.sendingFlag&&(
                        <div style={{marginTop:10, padding: "0 45px"}}><Spinner animation="border" variant="info"/><span style={{marginTp:10, fontWeight: "bold", fontSize: 16}}> {trls('Sending')}...</span></div>
                    )}
                    <div className="place-and-orders__top">
                        <Row className="product-detail__data-div">
                            <Col sm={6}>
                                <div>
                                    <Form.Label>
                                        {trls("Productcode")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.productcode}</p>
                                </div>
                                <div style={{paddingTop: 20}}>
                                    <Form.Label>
                                        {trls("Supplier")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.Supplier}</p>
                                </div>
                                <div style={{paddingTop: 20, paddingBottom: 20}} className={detailData.Note !== "" ? "" : "d-none" }>
                                    <Form.Label>
                                        {trls("Comments")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.Note}</p>
                                </div>
                                <div style={{padding: "20px 0px", paddingTop: 0, position: 'relative'}}>
                                    <div id="react-file-drop-demo" className = "purhcase-order__doc">
                                        {productDocList.length>0 && (
                                            productDocList.map((data,i) =>(
                                                <div id={i} key={i}>
                                                    <span className="docList-text" onClick={()=>this.downLoadFile(data.FileStorageId)}>{data.FileName}</span>
                                                </div>
                                            ))
                                        )
                                        }
                                    </div>
                                    <label className="placeholder-label_purchase purhcase-placeholder">{trls('File')}</label>
                                </div>
                                <div className="d-flex flex-row">
                                    <button type="button" className="btn btn-primary-lg d-flex justify-content-center align-items-center" style={{ backgroundColor: "#FFDB2F"}} onClick={()=>this.setState({modalEditShow:true, exactFlag: false})}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button><br/>
                                    <OverlayTrigger
                                    key={"exact"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-${"exact"}`}>
                                        {trls("Send_to_Exact")}
                                        </Tooltip>
                                    }
                                    >
                                        <button type="button" className="btn btn-primary-lg ml-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#FFDB2F"}} title="Exact" onClick={()=>this.generateProductXml()}><img src={require("../../assets/images/exact.png")} className="img-icon" alt="edit"/></button>
                                    </OverlayTrigger> 
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div>
                                    <Form.Label>
                                        {trls("Product")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.product}</p>
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <Form.Label>
                                        {trls("Customer")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.Customer}</p>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div>
                                    <Form.Label>
                                        {trls("Productgroup")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.Productgroup}</p>
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <Form.Label>
                                        {trls("Salesunit")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.Salesunit}</p>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div>
                                    <Form.Label>
                                        {trls("Purchase_Unit")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.PurchaseUnit}</p>
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <Form.Label>
                                        {trls("Kilogram")}
                                    </Form.Label>
                                    <p className="product-detail__data">{detailData.kilogram}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={"product-detail-table"}>
                        <div className="product-price-table">
                            <div className="purchase-price__div">
                                <p className="purprice-title"><i className="fas fa-caret-right add-icon" style={{color: "#2CB9D9"}}></i>{this.state.option1}{trls("Purchase_Price")}</p>
                                <select className="form-control ml-3" value={this.state.option1} style={{width: "140px", margin: "3px"}} onChange={(e) => this.filterPriceToggle(e, "priceTbody", 1)}>
                                    <option value="latest">Toon nieuwste</option>
                                    <option value="all">Toon alles</option>
                                </select>
                                <Button variant="primary" className="btn-icon ml-auto" style={{marginLeft: "auto"}} onClick={()=>this.setState({modalShow:true, price_flag:1})}><i className="fas fa-plus add-icon"></i></Button>
                            </div>
                            <div className="prurprice-table__div">
                                <table className="place-and-orders__table table table--striped prurprice-dataTable">
                                    <thead>
                                        <tr>
                                            <th>{trls("Price")}</th>
                                            <th>{trls("Start_date")}</th>
                                            <th>{trls("End_date")}</th>
                                            <th style={{width:"10%"}}>{trls("Approve")}</th>
                                            <th style={{width: 250}}>{trls("Action")}</th>
                                        </tr>
                                    </thead>
                                        {purpriceData &&(<tbody id="priceTbody">
                                            {
                                                purpriceData.map((data,i) =>(
                                                <tr id={i} key={i} style={{verticalAlign:"middle"}}>
                                                    <td>{Common.formatMoney(data.Price)}</td>
                                                    <td>{Common.formatDate(data.StartDate)}</td>
                                                    <td>{Common.formatDate(data.EndDate)}</td>
                                                    {!data.isApproved?(
                                                        <td style={{textAlign:"center", paddingBottom:"0px", paddingTop:"0px"}}><Button id={data.Id} name="purchase" type="submit" style={{height:"31px",fontSize:"12px"}} onClick={this.priceApproveConfirm}>{trls('Approve')}</Button></td>
                                                    ):<td style={{textAlign:"center"}}></td>}
                                                    <td>
                                                        <Row className="d-flex justify-content-between" style={{width: "100px"}}>
                                                            {data.canDelete !== "false"&&(
                                                                <button type="button" id={data.Id} className="delete-btn mr-2 my-1" onClick={()=>this.deletePrice(data.Id, 1)}><img src={require("../../assets/images/delete.svg")} alt="delete"/></button>
                                                            )}
                                                            <button type="button" id={data.Id} className="edit-btn my-1" onClick={()=>this.setState({ price_flag:1, editPriceFlag: true, editPriceData: data, modalShow: true})}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>)}
                                </table>
                            </div>
                        </div>
                        <div className="product-price-table mt-5">
                            <div className="purchase-price__div">
                                <p className="purprice-title"><i className="fas fa-caret-right add-icon" style={{color: "#2CB9D9"}}></i>{trls("Sales_Price")}</p>
                                <select className="form-control ml-3" value={this.state.option2} style={{width: "140px", margin: "3px"}} onChange={(e) => this.filterPriceToggle(e, "salesTbody", 1)}>
                                    <option value="latest">Toon nieuwste</option>
                                    <option value="all">Toon alles</option>
                                </select>
                                <Button variant="primary" className="btn-icon ml-auto" style={{marginLeft: "auto"}} onClick={()=>this.setState({modalShow:true, price_flag:2})}><i className="fas fa-plus add-icon"></i></Button>
                            </div>
                            <div className="prurprice-table__div">
                                <table className="place-and-orders__table table table--striped prurprice-dataTable">
                                    <thead>
                                    <tr>
                                        <th>{trls("Price")}</th>
                                        <th>{trls("Start_date")}</th>
                                        <th>{trls("End_date")}</th>
                                        <th style={{width:"10%"}}>{trls("Approve")}</th>
                                        <th style={{width: 250}}>{trls("Action")}</th>
                                    </tr>
                                    </thead>
                                    {salespriceData &&(<tbody id="salesTbody">
                                        {
                                            salespriceData.map((data,i) =>(
                                            <tr id={i} key={i}>
                                                <td>{Common.formatMoney(data.Price)}</td>
                                                <td>{Common.formatDate(data.StartDate)}</td>
                                                <td>{Common.formatDate(data.EndDate)}</td>
                                                {!data.isApproved?(
                                                    <td style={{textAlign:"center", paddingBottom:"0px", paddingTop:"0px"}}><Button id={data.Id} name="sales" type="submit" style={{height:"31px",fontSize:"12px"}} onClick={this.priceApproveConfirm}>{trls('Approve')}</Button></td>
                                                ):<td style={{textAlign:"center"}}></td>}
                                                <td>
                                                    <Row className="d-flex justify-content-between" style={{width: "100px"}}>
                                                        {data.canDelete !== "false"&&(
                                                            <button type="button" id={data.Id} className="delete-btn mr-2 my-1" onClick={()=>this.deletePrice(data.Id, 2)}><img src={require("../../assets/images/delete.svg")} alt="delete"/></button>
                                                        )}
                                                        <button type="button" id={data.Id} className="edit-btn my-1" onClick={()=>this.setState({ price_flag:2, editPriceFlag: true, editPriceData: data, modalShow: true})}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                                    </Row>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>)}
                                </table>
                            </div>
                        </div>
                        <div className="product-price-table transport mt-5">
                            <div className="purchase-price__div">
                                <p className="purprice-title"><i className="fas fa-caret-right add-icon" style={{color: "#2CB9D9"}}></i>{trls("Transport_Price")}</p>
                                <select className="form-control ml-3" value={this.state.option3} style={{width: "140px", margin: "3px"}} onChange={(e) => this.filterPriceToggle(e, "transportTbody", 3)}>
                                    <option value="latest">Toon nieuwste</option>
                                    <option value="all">Toon alles</option>
                                </select>
                                <Button variant="primary" className="btn-icon ml-auto" style={{marginLeft: "auto"}} onClick={()=>this.setState({modalShow:true, price_flag:3})}><i className="fas fa-plus add-icon"></i></Button>
                            </div>
                            <div className="prurprice-table__div">
                                <table className="place-and-orders__table table table--striped prurprice-dataTable">
                                    <thead>
                                    <tr>
                                        <th>{trls('Transporter')}</th>
                                        <th>{trls('Pricingtype')}</th>
                                        <th>{trls('Price')}</th>
                                        <th>{trls('Start_date')}</th>
                                        <th>{trls('End_date')}</th>
                                        <th style={{width:"10%"}}>{trls("Approve")}</th>
                                        <th style={{width: 200}}>{trls("Action")}</th>
                                    </tr>
                                    </thead>
                                    {transportData &&(<tbody id="transportTbody">
                                        {
                                            transportData.map((data,i) =>(
                                            <tr id={i} key={i}>
                                                <td>{data.Transporter}</td>
                                                <td>{pricingtypelist[data.pricingtype]}</td>
                                                <td>{Common.formatMoney(data.price)}</td>
                                                <td>{Common.formatDate(data.startdate)}</td> 
                                                <td>{Common.formatDate(data.enddate)}</td> 
                                                {!data.isApproved?(
                                                    <td style={{textAlign:"center", paddingBottom:"0px", paddingTop:"0px"}}><Button id={data.Id} name="transport" type="submit" style={{height:"31px",fontSize:"12px"}} onClick={this.priceApproveConfirm}>{trls('Approve')}</Button></td>
                                                ):<td style={{textAlign:"center"}}></td>}
                                                <td>
                                                    <Row className="d-flex justify-content-between" style={{width: "100px"}}>
                                                        <button type="button" id={data.Id} className="edit-btn my-1" onClick={()=>this.setState({ price_flag:3, editPriceFlag: true, editPriceData: data, modalShow: true})}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                                    </Row>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>)}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Priceform
                    show={this.state.modalShow}
                    onHide={() => this.setState({modalShow: false})}
                    productid={this.props.productid}
                    price_flag={this.state.price_flag}
                    onGetPurchasePrice={this.getPurchasePriceData}
                    onGetSalesPrice={this.getSalespriceData}
                    onGetTransportPrice={this.getTransportPriceData}
                    editpriceflag={this.state.editPriceFlag}
                    editpricedata={this.state.editPriceData}
                    viewPurchaseLine={(startDate, endDate, newPrice, price_flag, transportCode)=>this.viewPurchaseLine(startDate, endDate, newPrice, price_flag, transportCode)}
                    onRemoveState={()=>this.removeState()}               
                />
                <Updateproductform
                    show={this.state.modalEditShow}
                    onHide={() => this.setState({modalEditShow: false})}
                    getproductetails={()=>this.getProductDetails()}
                    productid={this.props.productid}
                    copyflag={0}
                    copyproduct = {detailData}
                    files = {[]}
                />
                <Pricelinechangeform
                    show={this.state.priceLineShowModal}
                    onHide={() => this.setState({priceLineShowModal: false, purchaseLineData: [], newPrice: ''})}
                    pricelinedata={this.state.priceLineData}
                    newprice={this.state.newPrice}
                    price_flag={this.state.price_flag}
                />
            </div>
        )
    };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Productdtail);
