import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Col, Row } from "react-bootstrap";
import Purchaseform from "./purchaseform";
import { LineScale } from "react-pure-loaders";
import SessionManager from "../../components/session_manage";
import API from "../../components/api";
import Axios from "axios";
import { trls } from "../../components/translate";
import "datatables.net";
import $ from "jquery";
import * as Common from "../../components/common";
import Purchaseorderdetail from "./purchaseorder_detail";
import Filtercomponent from "../../components/filtercomponent";
import Contextmenu from "../../components/contextmenu";
import SweetAlert from "sweetalert";
import * as Auth from "../../components/auth";
import Select from "react-select";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Purchaseorder extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.setSent = this.sentToExact.bind(this);

    this.state = {
      purhaseorders: [],
      loading: true,
      slideFormFlag: false,
      slideDetailFlag: false,
      filterData: [],
      originpurchaseorders: [],
      filterFlag: false,
      filterColunm: [
        { label: "Id", value: "Id", type: "text", show: true },
        { label: "Supplier", value: "Supplier", type: "text", show: true },
        { label: "Invoice", value: "invoicenr", type: "text", show: true },
        {
          label: "Invoice_date",
          value: "invoicedate",
          type: "date",
          show: true,
        },
        { label: "totalExclVat", value: "totalExclVAT", type: "text", show: true },
        { label: "TotalVat", value: "totalVAT", type: "text", show: true },
        { label: "TotalInclVat", value: "total", type: "text", show: true },
        {
          label: "ExactBooking",
          value: "ExactBooking",
          type: "text",
          show: true,
        },
        { label: "ExactStatus", value: "exactStatus", type: "text", show: true },
        { label: "ExactError", value: "exactError", type: "text", show: true },
        { label: "Action", value: "Action", type: "text", show: true },
      ],
      packingFilterNum: "",
      obj: this,
      userInfo: Auth.getUserInfo(),
      showMode: 2,
    };
  }
  componentDidMount() {
    this.getPurchaseOrders();
    this.setFilterData();
    let obj = this.state.obj;
    $("#packing-filter").keyup(function (event) {
      if (event.keyCode === 13) {
        obj.getPurchaseOrders();
      }
    });
  }

  sentToExact() {
    this.setState({ sentToExact: 1 });
  }

  componentWillUnmount() {}

  getPurchaseOrders(data) {
    const { packingFilterNum } = this.state;
    this.setState({ loading: true });
    $("#purchase_order tbody").css("display", "none");
    $("#purchase_order").dataTable().fnDestroy();
    var headers = SessionManager.shared().getAuthorizationHeader();
    let params = {
      pakbon: packingFilterNum,
    };
    Axios.post(API.GetPurchaseOrders, params, headers).then((result) => {
      if (!data) {
        this.setState(
          {
            purhaseorders: result.data.Items,
            originpurchaseorders: result.data.Items,
            loading: false,
          },
          () => {
            this.setDataTable(null);
          }
        );
      } else {
        this.setState({ purhaseorders: data, loading: false }, () => {
          this.setDataTable(null);
        });
      }
    });
  }

  setDataTable = () => {
    const { filterColunm, userInfo, purhaseorders } = this.state;
    let obj = this.state.obj;
    let dataTableData = [];
    let tempOrder = [];
    let filterPurchaseData = [];
    purhaseorders.map((value, index) => {
      if (this.state.showMode === 1) {
        filterPurchaseData.push(value);
      } else if (this.state.showMode === 2) {
        if (!value.exactBooking) {
          filterPurchaseData.push(value);
        }
      } else {
        if (value.exactBooking) {
          filterPurchaseData.push(value);
        }
      }
      return value;
    });
    filterPurchaseData.sort(function (a, b) {
      return a.id - b.id;
    });
    filterPurchaseData.map((order, index) => {
      tempOrder = [
        order.id,
        order.Supplier,
        order.invoicenr,
        Common.formatDate(order.invoicedate),
        Common.formatMoney(order.totalExclVAT),
        Common.formatMoney(order.totalVAT),
        Common.formatMoney(order.total),
        order.exactBooking,
        order.exactStatus,
        order.exactError,
        "",
      ];
      dataTableData.push(tempOrder);
      return order;
    });
    $(".fitler").on("keyup", function () {
      table.search(this.value).draw();
    });
    $("#purchase_order").dataTable().fnDestroy();

    let table = $("#purchase_order").DataTable({
      data: dataTableData,
      deferRender: true,
      createdRow: function (row, data, dataIndex) {
        filterColunm.map((colunm, index) => {
          if (colunm.show) {
            $("td:eq(" + index + ")", row).css("display", "contens");
          } else {
            $("td:eq(" + index + ")", row).css("display", "none");
          }
          return colunm;
        });
        $("td:eq(1)", row).css("width", "300px");
        $("td:eq(0)", row).html(
          "<div class='order-table__id' id=" +
            data[0] +
            ">" +
            data[0] +
            "</div>"
        );
        $("td:eq(4)", row).css("white-space", "nowrap");
        $("td:eq(5)", row).css("white-space", "nowrap");
        $("td:eq(6)", row).css("white-space", "nowrap");
        $("td:eq(7)", row).html(
          data[7]
            ? "<div class='d-flex justify-content-center align-items-center' style='width: 85px'><i class='fas fa-check-circle order-booking__icon-active'></i><span class='ml-1'>" +
                data[7] +
                "</span></div>"
            : "<div class='row'><i class='fas fa-times-circle order-booking__icon-inactive'></i><span class='exact-booking__number'></span></div>"
        );
        $("td:eq(8)", row).css("white-space", "nowrap");
        $("td:eq(9)", row).css("white-space", "nowrap");
        if (userInfo.roles === "Administrator") {
          $("td:eq(10)", row).html(
            "<div class='row'><button id=" +
              data[0] +
              " type='button' class='delete-btn button-delete delete-order'><img src='" + require('../../assets/images/delete.svg') + "' alt='delete'/></button></div>"
          );
        }
      },
      language: {
        lengthMenu: trls("Show") + " _MENU_ " + trls("Result_on_page"),
        zeroRecords: "Nothing found - sorry",
        info: trls("Show_page") + " _PAGE_ " + trls("Results_of") + " _PAGES_",
        infoEmpty: "No records available",
        infoFiltered: "(filtered from _MAX_ total records)",
        search: trls("Search"),
        paginate: {
          previous: trls("Previous"),
          next: trls("Next"),
        },
      },
      dom: 't<"bottom-datatable" lip>',
      order: [[0, "desc"]],
    });
    $("#purchase_order tbody").css("display", "contents");
    $("#purchase_order").on("click", ".delete-order", function () {
      obj.deletePurchaseOrder(this.id);
    });
    $("#purchase_order").on("click", ".order-table__id", function () {
      obj.loadPurchaseDetail(this.id);
    });
  };

  loadPurchaseDetail = (id) => {
    this.setState({
      newId: id,
      supplierCode: "",
      newSubmit: true,
      sentToExact: this.state.purhaseorders.find(x => x.id === parseInt(id)) ? this.state.purhaseorders.find(x => x.id === parseInt(id)).sendToExact : 0,
      slideDetailFlag: true,
    });
  };

  addPurchase = () => {
    this.setState({ copyProduct: "", copyFlag: 1, slideFormFlag: true });
    Common.showSlideForm();
  };
  // filter module
  setFilterData = () => {
    let filterData = [
      { label: trls("Supplier"), value: "Supplier", type: "text" },
      { label: trls("Invoice"), value: "invoicenr", type: "text" },
      { label: trls("Pakbon"), value: "pakbon ", type: "text" },
      { label: trls("Invoice_date"), value: "invoicedate", type: "date" },
      { label: trls("Totalamount"), value: "total", type: "text" },
    ];
    this.setState({ filterData: filterData });
  };

  filterOptionData = (filterOption) => {
    let dataA = [];
    dataA = Common.filterData(filterOption, this.state.originpurchaseorders);
    if (!filterOption.length) {
      dataA = null;
    }
    $("#example").dataTable().fnDestroy();
    this.getPurchaseOrders(dataA);
  };

  changeFilter = () => {
    if (this.state.filterFlag) {
      this.setState({ filterFlag: false });
    } else {
      this.setState({ filterFlag: true });
    }
  };
  // filter module

  removeColumn = (value) => {
    let filterColunm = this.state.filterColunm;
    filterColunm = filterColunm.filter(function (item, key) {
      if (trls(item.label) === value) {
        item.show = false;
      }
      return item;
    });
    this.setState({ filterColunm: filterColunm });
  };

  showColumn = (value) => {
    let filterColum = this.state.filterColunm;
    filterColum = filterColum.filter((item, key) => item.label === value);
    return filterColum[0].show;
  };

  deletePurchaseOrder = (id) => {
    let params = {
      id: id,
    };
    var header = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.DeletePurchaseOrder, params, header).then((result) => {
      if (result.data.Success) {
        SweetAlert({
          title: trls("Success"),
          icon: "success",
          button: "OK",
        });
        this.getPurchaseOrders();
      }
    });
  };

  changeShowMode = (value) => {
    this.setState({ showMode: value }, () => {
      this.setDataTable();
    });
  };

  render() {
    const { filterColunm } = this.state;
    const showModeList = [
      { value: 1, label: trls("Show_all") },
      { value: 2, label: trls("Show_just_not_completed") },
      { value: 3, label: trls("Show_just_completed") },
    ];
    return (
      <div className="order_div">
        <div className="content__header content__header--with-line">
          <h2 className="title">{trls("Purchase_Order")}</h2>
        </div>
        <div className="orders">
          <Row>
            <Col sm={12} md={2} className="pl-0 my-2 my-md-0">
              <Button
                variant="primary"
                onClick={() => this.addPurchase()}
                className="btn-icon"
              >
                <i className="fas fa-plus add-icon"></i>
              </Button>

            </Col>
            <Col sm={12} md={9} xl={6} className="has-search mt-0">
              <Row className="d-flex justify-content-between">
                <Col sm={12} md={5} className="d-flex flex-row px-0 my-2 my-md-0">
                  <Select
                    name="filter"
                    options={showModeList}
                    className="select-show-class mr-3"
                    onChange={(val) => this.changeShowMode(val.value)}
                    defaultValue={{
                      value: "2",
                      label: trls("Show_just_not_completed"),
                    }}
                  />
                  <Button variant="light" className="btn-icon" onClick={() => this.changeFilter()}>
                    <i className="fas fa-filter add-icon"></i>
                  </Button>
                </Col>
                <Col sm={12} md={6} className="d-flex d-flex justify-content-md-end px-0 my-2 my-md-0">
                <div className="mr-3">
                  <i className="fas fa-filter form-control-feedback"></i>
                  <Form.Control
                    id="packing-filter"
                    className="form-control"
                    type="text"
                    placeholder={trls("PackingFilter")}
                    onChange={(evt) =>
                      this.setState({ packingFilterNum: evt.target.value })
                    }
                  />
                </div>
                
                <div>
                  <span className="fa fa-search form-control-feedback text-light-grey"></span>
                  <Form.Control
                    className="form-control fitler"
                    type="text"
                  />
                </div>
                </Col>
              </Row>
            </Col>
            {this.state.filterData.length && (
              <Filtercomponent
                onHide={() => this.setState({ filterFlag: false })}
                filterData={this.state.filterData}
                onFilterData={(filterOption) =>
                  this.filterOptionData(filterOption)
                }
                showFlag={this.state.filterFlag}
              />
            )}
          </Row>
          <div className="table-responsive purchase-order-table">
            <table
              id="purchase_order"
              className="place-and-orders__table table"
              width="100%"
            >
              <thead>
                <tr>
                  {filterColunm.map((item, key) => (
                    <th
                      className={!item.show ? "filter-show__hide" : ""}
                      key={key}
                      style={item.value === "ExactBooking" ? { width: 30 } : {}}
                    >
                      <Contextmenu
                        triggerTitle={trls(item.label) ? trls(item.label) : ""}
                        // addFilterColumn = {(value)=>this.addFilterColumn(value)}
                        removeColumn={(value) => this.removeColumn(value)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
            {this.state.loading && (
              <div
                className="col-md-4 offset-md-4 col-xs-12 loading"
                style={{ textAlign: "center" }}
              >
                <LineScale color={"#222A42"} loading={this.state.loading} />
              </div>
            )}
          </div>
        </div>
        {this.state.slideFormFlag ? (
          <Purchaseform
            show={this.state.modalShow}
            onHide={() => this.setState({ slideFormFlag: false })}
            onloadPurchaseDetail={(purchaseId) =>
              this.loadPurchaseDetail(purchaseId)
            }
          />
        ) : null}
        {this.state.slideDetailFlag ? (
          <Purchaseorderdetail
            onHide={() => this.setState({ slideDetailFlag: false })}
            newId={this.state.newId}
            sentToExact={this.state.sentToExact}
            supplierCode={this.state.supplierCode}
            setSent={this.setSent}
            onGetgetPurchaseOrders={() => this.getPurchaseOrders()}
          />
        ) : null}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Purchaseorder);
