import React, {Component} from 'react'
import { Form, Row, Button, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import $ from 'jquery';
import SessionManager from '../../components/session_manage';
import API from '../../components/api'
import Axios from 'axios';
import { LineScale } from 'react-pure-loaders';
import { trls } from '../../components/translate';
import 'datatables.net';
import Filtercomponent from '../../components/filtercomponent';
import Contextmenu from '../../components/contextmenu';
import * as Common  from '../../components/common';
import AddPeriodForm from './addPeriodForm';
import { confirmAlert } from 'react-confirm-alert'; // Import

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    
});
class PeriodDateManage extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {  
            periodData: [],
            filterColunm: [
                {"label": 'Id', "value": "Id", "type": 'text', "show": true},
                {"label": 'Year', "value": "Jaar", "type": 'text', "show": true},
                {"label": 'Month', "value": "Maand", "text": 'text', "show": true},
                {"label": 'NewDate', "value": "NieuweDatum", "type": 'date', "show": true},
                {"label": 'Action', "value": "action", "type": 'text', "show": true},
            ],
            filterData: [],
            originFilterData: [],
            slideDetailFlag: false,
            updatePeriodData: [],
        };
    }

    componentDidMount() {
        this._isMounted=true
        this.getPeriodData(null);
        this.setFilterData();
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getPeriodData (data) {
        this._isMounted = true;
        this.setState({loading:true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetPeriodeDatum, headers)
        .then(result => {
            if(this._isMounted){
                if(!data){
                    let itemData = result.data.Items;
                    this.setState({periodData: itemData, originFilterData: result.data.Items});
                }else{
                    this.setState({periodData: data});
                }
                this.setState({loading:false})
                $('.fitler').on( 'keyup', function () {
                    table.search( this.value ).draw();
                } );
                $('#periodDate_table').dataTable().fnDestroy();
                var table = $('#periodDate_table').DataTable(
                    {
                        "language": { 
                            "lengthMenu": trls("Show")+" _MENU_ "+trls("Entries"),
                            "zeroRecords": "Nothing found - sorry",
                            "info": trls("Show_page")+" _PAGE_ of _PAGES_",
                            "infoEmpty": "No records available",
                            "infoFiltered": "(filtered from _MAX_ total records)",
                            "search": trls('Search'),
                            "paginate": {
                                "previous": trls('Previous'),
                                "next": trls('Next')
                        }
                      },
                        "dom": 't<"bottom-datatable" lip>',
                        "ordering": false,
                        "bLengthChange" : false, 
                        "paging":false,   
                        "info": false
                    }
                  );
            }
        });
    }

    // filter module
    setFilterData = () => {
        let filterData = [
            {"label": trls('Year'), "value": "Jaar", "type": 'text', "show": true},
            {"label": trls('Month'), "value": "Maand", "text": 'text', "show": true},
            {"label": trls('NewDate'), "value": "NieuweDatum", "type": 'date', "show": true},
        ]
        this.setState({filterData: filterData});
    }

    filterOptionData = (filterOption) =>{
        let dataA = []
        dataA = Common.filterData(filterOption, this.state.originFilterData);
        if(!filterOption.length){
            dataA=null;
        }
        $('#periodDate_table').dataTable().fnDestroy();
        this.getPeriodData(dataA, this.state.filterDate);
    }

    changeFilter = () => {
        if(this.state.filterFlag){
            this.setState({filterFlag: false})
        }else{
            this.setState({filterFlag: true})
        }
    }
    // filter module
    loadSalesDetail = (data)=>{
        // Common.showSlideForm();
        this.setState({newId: data.id, slideDetailFlag: true})
    }

    addSales = () => {
        this.setState({copyProduct: '', copyFlag: 1, slideFormFlag: true});
        Common.showSlideForm();
    }

    removeColumn = (value) => {
        let filterColunm = this.state.filterColunm;
        filterColunm = filterColunm.filter(function(item, key) {
        if(trls(item.label)===value){
            item.show = false;
        }
        return item;
        })
        this.setState({filterColunm: filterColunm})
    }

    showColumn = (value) => {
        let filterColum = this.state.filterColunm;
        filterColum = filterColum.filter((item, key)=>item.label===value);
        return filterColum[0].show;
    }

    addFilterColumn = (value) => {
        // let filterColum = this.state.filterColunm;
        // let filterData = this.state.filterData;
        // let filterItem = [];
        // filterColum = filterColum.filter(function(item, key) {
        //   return item.label === value
        // })
        // filterItem = filterData.filter((item, key)=>item.label===value);
        // if(!filterItem[0]){
        //   filterData.push(filterColum[0]);
        // }
        // this.setState({filterData: filterData})
    }

    loadSalesDetail = (data)=>{
        this.setState({newId: data.id, slideDetailFlag: true})
    }

    onUpdatePriceData = (data) => {
        this.setState({updatePriceData: data, showUpdateChangePrice: true})
    }

    updatePeriodDate = (data) => {
        this.setState({slideFormFlag: true, updatePeriodData: data, mode: 'update'})
        Common.showSlideForm();
    }

    addPeriodDate = () => {
        this.setState({slideFormFlag: true, mode: 'add'})
        Common.showSlideForm();
    }

    deletePeriodDate = (id) => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        let params = []
        params = {
            id: id
        }
        Axios.post(API.DeletePeriodeDatum, params, headers)
        .then(result => {
            this.setState({loading:true})
            this.getPeriodData();    
        }
        )
    }

    periodDeleteConfirm = (id) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Delete',
                onClick: () => {
                   this.deletePeriodDate(id)
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
        });
    }

    render () {
        const { filterColunm, periodData } = this.state;
        return (
            <div className="order_div">
                <div className="content__header content__header--with-line">
                    <div id="google_translate_element"></div>
                    <h2 className="title">{trls("Period Date")}</h2>
                </div>
                <div className="orders">
                    <Row>
                        <Col sm={4} className="d-flex align-items-center pl-0">
                            <Button variant="primary" className="btn-icon" onClick={()=>this.addPeriodDate()}><i className="fas fa-plus add-icon"></i></Button> 
                        </Col>
                        <Col sm={8} className="has-search">
                            <div style={{display: 'flex', float: 'right'}}>
                                <Button variant="light" className="btn-icon" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i></Button>   
                                <div style={{marginLeft: 20}}>
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <Form.Control className="form-control fitler" type="text" name="number"/>
                                </div>
                            </div>
                        </Col>
                        {this.state.filterData.length>0&&(
                            <Filtercomponent
                                onHide={()=>this.setState({filterFlag: false})}
                                filterData={this.state.filterData}
                                onFilterData={(filterOption)=>this.filterOptionData(filterOption)}
                                showFlag={this.state.filterFlag}
                            />
                        )}
                    </Row>
                    <div className="table-responsive">
                        <table id="periodDate_table" className="place-and-orders__table table" width="100%">
                            <thead>
                            <tr>
                                {filterColunm.map((item, key)=>(
                                    <th className={!item.show ? "filter-show__hide" : ''} key={key}>
                                        <Contextmenu
                                            triggerTitle = {trls(item.label) ? trls(item.label) : ''}
                                            addFilterColumn = {(value)=>this.addFilterColumn(value)}
                                            removeColumn = {(value)=>this.removeColumn(value)}
                                        />
                                    </th>
                                    )
                                )}
                            </tr>
                            </thead>
                            {periodData && !this.state.loading&&(<tbody>
                                {
                                periodData.map((data,i) =>(
                                    <tr id={data.id} key={i}>
                                        <td className={!this.showColumn(filterColunm[0].label) ? "filter-show__hide" : ''}>
                                            <div id={data['Id']} style={{color:'#004388', fontSize:"14px", fontWeight:'bold'}}>{data['Id']}</div>    
                                        </td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[1].label) ? "filter-show__hide" : ''}>{data['Jaar']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[2].label) ? "filter-show__hide" : ''}>{data['Maand']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[3].label) ? "filter-show__hide" : ''}>{Common.formatDate(data['NieuweDatum'])}</td>
                                        <td style={{color: data.color, width: 230}} className={!this.showColumn(filterColunm[4].label) ? "filter-show__hide" : ''}>
                                            <div className="d-flex flex-row">
                                                <button type="button" className="delete-btn mr-2 my-1" onClick={()=>this.periodDeleteConfirm(data['Id'])}><img src={require("../../assets/images/delete.svg")} alt="delete"/></button>
                                                <button type="button" className="edit-btn my-1" onClick={()=>this.updatePeriodDate(data)}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>)}
                        </table>
                        { this.state.loading&& (
                        <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                            <LineScale
                                color={'#2D3840'}
                                loading={this.state.loading}
                            />
                        </div>
                        )}
                    </div>
                </div>
                {this.state.slideFormFlag ? (
                    <AddPeriodForm
                        show={this.state.modalShow}
                        mode={this.state.mode}
                        onPeriodData={()=>this.getPeriodData()}
                        updatePeriodData={this.state.updatePeriodData}
                        onHide={() => this.setState({slideFormFlag: false})}
                    /> 
                ): null}
            </div>
        )
        };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(PeriodDateManage);
