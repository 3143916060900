import React, {Component} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import SessionManager from '../../components/session_manage';
import API from '../../components/api'
import Axios from 'axios';
import { trls } from '../../components/translate';
import * as Common from '../../components/common';
import DatePicker from "react-datepicker";

const mapStateToProps = state => ({ 
    ...state.auth,
});
const mapDispatchToProps = (dispatch) => ({
});
class AddPeriodForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        const { updatePeriodData, mode } = props
        this.state = {
            newPeriodDate: mode==="update" ? updatePeriodData['NieuweDatum'] : new Date(),
            changeDateFlag: false
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (event) => {
        const { updatePeriodData } = this.props
        event.preventDefault();
        const clientFormData = new FormData(event.target);
        const data = {};
        for (let key of clientFormData.keys()) {
            data[key] = clientFormData.get(key);
        }
        if(this.props.mode==="add"){
            let params = {
                "year": Number(data.year),
                "month": Number(data.month),
                "newdate": Common.formatDateSecond(data.newDate),
            }
            var headers = SessionManager.shared().getAuthorizationHeader();
            Axios.post(API.postPeriodeDatum, params, headers)
            .then(result => {
                this.props.onPeriodData();
                this.onHide();
            })
            .catch(err => {
            });
        }else{
            let params = {
                "id": updatePeriodData['Id'],
                "year": Number(data.year),
                "month": Number(data.month),
                "newdate": Common.formatDateSecond(data.newDate),
            }
            headers = SessionManager.shared().getAuthorizationHeader();
            Axios.post(API.PutPeriodeDatum, params, headers)
            .then(result => {
                this.props.onPeriodData()
                this.onHide();
            })
            .catch(err => {
            });
        }
    }

    onHide = () => {
        this.props.onHide();
        Common.hideSlideForm();
        this.setState({
            newPeriodDate:''
        })
    }

    onChangeDate = (date) => {
        this.setState({newPeriodDate: date, changeDateFlag: true})
    }

    dateValueKeyDown = (event) => {
        const charCode = event.which || event.keyCode;

        if (charCode === 9 ) {

            const newDate = new Date();
            newDate.setDate(parseInt(event.target.value));

            this.onChangeDate(newDate);
        }
    }

    render(){   
        const { updatePeriodData, mode } = this.props
        const { newPeriodDate } = this.state
        return (
            <div className = "slide-form__controls open" style={{height: "100%"}}>
                <div style={{marginBottom:30}}>
                    <i className="fas fa-times slide-close" style={{ fontSize: 20, cursor: 'pointer'}} onClick={()=>this.onHide()}></i>
                </div>
                <Form className="container" onSubmit = { this.handleSubmit }>
                    <Col className="title add-product">{mode==="add" ? trls('Add Period Date') : trls('Edit Period Date')}</Col>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <Form.Control type="number" name="year" max="9999" defaultValue={mode==="update" ? updatePeriodData['Jaar'] : ''} required placeholder={trls('Year')}/>
                            <label className="placeholder-label">{trls('Year')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <Form.Control type="number" name="month" max="12" defaultValue={mode==="update" ? updatePeriodData['Maand'] : ''} required placeholder={trls('Month')}/>
                            <label className="placeholder-label">{trls('Month')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <DatePicker name="newDate" className="myDatePicker" dateFormat={['dd-MM-yyyy', 'd-M-yyyy']} selected={new Date(newPeriodDate)} onKeyDown={(e) => this.dateValueKeyDown(e)} onChange = {(value)=>this.onChangeDate(value)}/>
                            <label className="placeholder-label">{trls('NewDate')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group >
                        <Col>
                            <Button type="submit" style={{width:"100px"}}>{trls('Save')}</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPeriodForm);