import React, {Component} from 'react'
import { Form, Row, Button, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import $ from 'jquery';
import SessionManager from '../../components/session_manage';
import API from '../../components/api'
import Axios from 'axios';
import { LineScale } from 'react-pure-loaders';
import { trls } from '../../components/translate';
import 'datatables.net';
import Filtercomponent from '../../components/filtercomponent';
import Contextmenu from '../../components/contextmenu';
import * as Common  from '../../components/common';
import dayjs from 'dayjs'
import DatePicker from "react-datepicker";
import UpdateChangePrice from './updateChangePrice';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    
});
class ChangePriceMange extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {  
            demurrageData: [],
            filterColunm: [
                {"label": 'Id', "value": "PriceId", "type": 'text', "show": true},
                {"label": 'Article seg 1', "value": "Artikel seg 1", "type": 'text', "show": true},
                {"label": 'Article seg 2', "value": "Article seg 2", "text": 'text', "show": true},
                {"label": 'Article seg 3', "value": "Article seg 3", "type": 'text', "show": true},
                {"label": 'Start date', "value": "BeginDatum", "type": 'date', "show": true},
                {"label": 'Unit', "value": "Enheid", "type": 'text', "show": true},
                {"label": 'End Date', "value": "BeginDatum", "type": 'date', "show": true},
                {"label": 'Price', "value": "Prijs", "type": 'date', "show": true},
                {"label": 'Product', "value": "Product", "type": 'text', "show": true},
                {"label": 'ProductCode', "value": "ProductCode", "type": 'text', "show": true},
                {"label": 'Relationship Code', "value": "Relatiecode", "type": 'text', "show": true},
                {"label": 'Relationship Name', "value": "Relatienaam", "type": 'text', "show": true},
                {"label": 'Price type', "value": "Relatienaam", "type": 'text', "show": true},
                {"label": 'Action', "value": "action", "type": 'text', "show": true},
            ],
            filterData: [],
            originFilterData: [],
            slideDetailFlag: false,
            filterDate: new Date(),
            showUpdateChangePrice: false,
            updatePriceData: []
        };
      }
    componentDidMount() {
        this._isMounted=true
        this.getPriceChangeData(null, this.state.changeDate);
        this.setFilterData();
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    getPriceChangeData (data, changeDate) {
        this._isMounted = true;
        this.setState({loading:true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        
        Axios.get(API.GetPriceChangeData+dayjs(changeDate).format('YYYY-MM-DD'), headers)
        .then(result => {
            if(this._isMounted){
                if(!data){
                    let itemData = result.data.Items;
                    itemData.sort(function(a, b){return new Date(a.BeginDatum) - new Date(b.BeginDatum)});
                    this.setState({priceChangeData: itemData, originFilterData: result.data.Items});
                }else{
                    let itemData = data;
                    itemData.sort(function(a, b){return new Date(a.BeginDatum) - new Date(b.BeginDatum)});
                    this.setState({priceChangeData: data});
                }
                this.setState({loading:false})
                $('.fitler').on( 'keyup', function () {
                    table.search( this.value ).draw();
                } );
                $('#priceChangeData_table').dataTable().fnDestroy();
                var table = $('#priceChangeData_table').DataTable(
                    {
                        "language": { 
                            "lengthMenu": trls("Show")+" _MENU_ "+trls("Entries"),
                            "zeroRecords": "Nothing found - sorry",
                            "info": trls("Show_page")+" _PAGE_ of _PAGES_",
                            "infoEmpty": "No records available",
                            "infoFiltered": "(filtered from _MAX_ total records)",
                            "search": trls('Search'),
                            "paginate": {
                                "previous": trls('Previous'),
                                "next": trls('Next')
                        }
                      },
                        "dom": 't<"bottom-datatable" lip>',
                        "ordering": false,
                        "bLengthChange" : false, 
                        "paging":false,   
                        "info": false
                    }
                  );
            }
        });
    }

    // filter module
    setFilterData = () => {
        let filterData = [
            {"label": trls('Id'), "value": "PriceId", "type": 'text', "show": true},
            {"label": trls('Article seg 1'), "value": "Artikel seg 1", "type": 'text', "show": true},
            {"label": trls('Article seg 2'), "value": "Article seg 2", "text": 'text', "show": true},
            {"label": trls('Article seg 3'), "value": "Article seg 3", "type": 'text', "show": true},
            {"label": trls('Start date'), "value": "BeginDatum", "type": 'date', "show": true},
            {"label": trls('Unit'), "value": "Enheid", "type": 'text', "show": true},
            {"label": trls('End Date'), "value": "BeginDatum", "type": 'date', "show": true},
            {"label": trls('Price'), "value": "Prijs", "type": 'date', "show": true},
            {"label": trls('Product'), "value": "Product", "type": 'text', "show": true},
            {"label": trls('ProductCode'), "value": "ProductCode", "type": 'text', "show": true},
            {"label": trls('Relationship Code'), "value": "Relatiecode", "type": 'text', "show": true},
            {"label": trls('Relationship Name'), "value": "Relatienaam", "type": 'text', "show": true},
            {"label": trls('Price type'), "value": "Relatienaam", "Soort prijs": 'text', "show": true},
        ]
        this.setState({filterData: filterData});
    }

    filterOptionData = (filterOption) =>{
        let dataA = []
        dataA = Common.filterData(filterOption, this.state.originFilterData);
        if(!filterOption.length){
            dataA=null;
        }
        $('#priceChangeData_table').dataTable().fnDestroy();
        this.getPriceChangeData(dataA, this.state.filterDate);
    }

    changeFilter = () => {
        if(this.state.filterFlag){
            this.setState({filterFlag: false})
        }else{
            this.setState({filterFlag: true})
        }
    }
    // filter module
    loadSalesDetail = (data)=>{
        // Common.showSlideForm();
        this.setState({newId: data.id, slideDetailFlag: true})
    }

    addSales = () => {
        this.setState({copyProduct: '', copyFlag: 1, slideFormFlag: true});
        Common.showSlideForm();
    }

    removeColumn = (value) => {
        let filterColunm = this.state.filterColunm;
        filterColunm = filterColunm.filter(function(item, key) {
        if(trls(item.label)===value){
            item.show = false;
        }
        return item;
        })
        this.setState({filterColunm: filterColunm})
    }

    showColumn = (value) => {
        let filterColum = this.state.filterColunm;
        filterColum = filterColum.filter((item, key)=>item.label===value);
        return filterColum[0].show;
    }

    addFilterColumn = (value) => {
        // let filterColum = this.state.filterColunm;
        // let filterData = this.state.filterData;
        // let filterItem = [];
        // filterColum = filterColum.filter(function(item, key) {
        //   return item.label === value
        // })
        // filterItem = filterData.filter((item, key)=>item.label===value);
        // if(!filterItem[0]){
        //   filterData.push(filterColum[0]);
        // }
        // this.setState({filterData: filterData})
    }

    loadSalesDetail = (data)=>{
        this.setState({newId: data.id, slideDetailFlag: true})
    }

    onChangeFilterDate = (date, e) => {
        this.setState({filterDate: date})
        this.getPriceChangeData(null, date);
    }

    onUpdatePriceData = (data) => {
        this.setState({updatePriceData: data, showUpdateChangePrice: true})
    }

    render () {
        const {filterColunm, priceChangeData, showUpdateChangePrice, updatePriceData, changeDate} = this.state;
        return (
            <div className="order_div">
                <div className="content__header content__header--with-line">
                    <div id="google_translate_element"></div>
                    <h2 className="title">{trls("Change Price")}</h2>
                </div>
                <div className="orders">
                    <Row>
                        <Col sm={4} className="d-flex align-items-center pl-0">
                            <span className="mr-3">{trls("FilterDate")}: </span>
                            <DatePicker name="filterDate" className="myDatePicker price-change-filter-date" style={{width: 30}} dateFormat="yyyy-MM-dd" selected={new Date(this.state.filterDate)} onChange = {(value, e)=>this.onChangeFilterDate(value, e)} />
                        </Col>
                        <Col sm={8} className="has-search">
                            <div style={{display: 'flex', float: 'right'}}>
                                <Button variant="light" className="btn-icon" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i></Button>   
                                <div className="ml-4">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <Form.Control className="form-control fitler" type="text" name="number"/>
                                </div>
                            </div>
                        </Col>
                        {this.state.filterData.length>0&&(
                            <Filtercomponent
                                onHide={()=>this.setState({filterFlag: false})}
                                filterData={this.state.filterData}
                                onFilterData={(filterOption)=>this.filterOptionData(filterOption)}
                                showFlag={this.state.filterFlag}
                            />
                        )}
                    </Row>
                    <div className="table-responsive">
                        <table id="priceChangeData_table" className="place-and-orders__table table" width="100%">
                            <thead>
                            <tr>
                                {filterColunm.map((item, key)=>(
                                    <th className={!item.show ? "filter-show__hide" : ''} key={key}>
                                        <Contextmenu
                                            triggerTitle = {trls(item.label) ? trls(item.label) : ''}
                                            addFilterColumn = {(value)=>this.addFilterColumn(value)}
                                            removeColumn = {(value)=>this.removeColumn(value)}
                                        />
                                    </th>
                                    )
                                )}
                            </tr>
                            </thead>
                            {priceChangeData && !this.state.loading&&(<tbody>
                                {
                                priceChangeData.map((data,i) =>(
                                    <tr id={data.id} key={i}>
                                        <td className={!this.showColumn(filterColunm[0].label) ? "filter-show__hide" : ''}>
                                            <div id={data.id} style={{color:'#004388', fontSize:"14px", fontWeight:'bold'}}>{data['PriceId']}</div>    
                                        </td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[1].label) ? "filter-show__hide" : ''}>{data['Artikel seg 1']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[2].label) ? "filter-show__hide" : ''}>{data['Artikel seg 2']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[3].label) ? "filter-show__hide" : ''}>{data['Artikel seg 3']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[4].label) ? "filter-show__hide" : ''}>{Common.formatDate(data['BeginDatum'])}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[5].label) ? "filter-show__hide" : ''}>{data['Eenheid']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[6].label) ? "filter-show__hide" : ''}>{Common.formatDate(data['EindDatum'])}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[7].label) ? "filter-show__hide" : ''}>{data['Prijs']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[8].label) ? "filter-show__hide" : ''}>{data['Product']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[9].label) ? "filter-show__hide" : ''}>{data['ProductCode']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[10].label) ? "filter-show__hide" : ''}>{data['Relatiecode']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[11].label) ? "filter-show__hide" : ''}>{data['Relatienaam']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[12].label) ? "filter-show__hide" : ''}>{data['Soort prijs']}</td>
                                        <td style={{color: data.color}} className={!this.showColumn(filterColunm[12].label) ? "filter-show__hide" : ''}>
                                            <button type="button" className="edit-btn" onClick={()=>this.onUpdatePriceData(data)}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>)}
                        </table>
                        { this.state.loading&& (
                        <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                            <LineScale
                                color={'#2D3840'}
                                loading={this.state.loading}
                            />
                        </div>
                        )}
                    </div>
                </div>
                {showUpdateChangePrice && (
                    <UpdateChangePrice
                        show={showUpdateChangePrice}
                        onHide={()=>this.setState({showUpdateChangePrice: false})}
                        updatePriceData={updatePriceData}
                        onGetPriceData={()=>this.getPriceChangeData(null, changeDate)}
                    />
                )
                }
            </div>
        )
        };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(ChangePriceMange);
